import { getToken, getUser } from "../commons";
import { postAPICall } from "./axiosCommons";
import { removeUserSession } from "../commons";

export const loginUser = async (username, password, areaCode) => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "logins", {
      username: username,
      password: password,
      user_type: "admin",
      // area_code: areaCode,
    });
    let userData = response?.data?.data;
    if (userData) {
      localStorage.setItem("user", userData.id);
      localStorage.setItem("name", userData.name);
      localStorage.setItem("role", userData.role);
      localStorage.setItem("token", userData.token);
    }
    return { data: response };
  } catch (error) {
    return { error: error.response };
  }
};

export const logoutUser = async () => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "logouts", {
      requester: getUser(),
      token: getToken(),
      login_id: getUser(),
    });
    removeUserSession();
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
