import React, { useState } from "react";
import styles from "./UserManage.styles";
import CustomToast from "../../components/CustomToast.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCopy } from "@fortawesome/free-solid-svg-icons";
import { addUser } from "../../utils/api/user";

function AddUser() {
  const [details, setMessage] = useState({ message: "", type: "" });
  const [showToast, setShowToast] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [fields, setFields] = useState({
    role: "",
    first_name: "",
    last_name: "",
    username: "",
    area_code: "",
    daily_quota: "0",
    type: "",
  });

  const handleFields = async () => {
    setDisabled(true);

    if (fields.role === "") {
      setMessage({ message: "Please select a role", type: "danger" });
      setShowToast(true);
      setDisabled(false);
      return;
    }

    if (fields.first_name === "") {
      setMessage({ message: "Please enter your first name", type: "danger" });
      setShowToast(true);
      setDisabled(false);
      return;
    }

    if (fields.last_name === "") {
      setMessage({ message: "Please enter your last name", type: "danger" });
      setShowToast(true);
      setDisabled(false);
      return;
    }

    if (fields.type === "") {
      setMessage({ message: "Please select a type", type: "danger" });
      setShowToast(true);
      setDisabled(false);
      return;
    }

    // contains number only
    if (
      fields.username === "" ||
      fields.username.length !== 11 ||
      !fields.username.match(/^[0-9]*$/)
    ) {
      setMessage({
        message: "Please enter a proper Mobile Number (11 digits)",
        type: "danger",
      });
      setShowToast(true);
      setDisabled(false);
      return;
    }

    if (
      fields.area_code === "" ||
      fields.area_code.length !== 6 ||
      !fields.area_code.match(/^[0-9]*$/)
    ) {
      setMessage({
        message: "Please enter a proper area code",
        type: "danger",
      });
      setShowToast(true);
      setDisabled(false);
      return;
    }

    // if (
    //   fields.daily_quota === "" ||
    //   !fields.daily_quota.match(/^[0-9.]*$/) ||
    //   parseFloat(fields.daily_quota) < 0
    // ) {
    //   setMessage({
    //     message: "Please enter a proper daily quota",
    //     type: "danger",
    //   });
    //   setShowToast(true);
    //   setDisabled(false);
    //   return;
    // }

    console.log("fields", fields);
    const response = await addUser(fields);
    if (!response?.data?.data?.messages?.error) {
      setPassword(response.data.data.password);
      setMessage({
        message: `User Added Successfully. Your Password is ${response.data.data.password}.`,
        type: "success",
      });
      setShowToast(true);
    } else {
      setMessage({
        message: response?.data?.data?.messages?.error,
        type: "error",
      });
    }

    setDisabled(false);
    setShowToast(true);
  };

  const handleMobileNumber = (number) => {
    // check if it is a valid number
    if (number.match(/^[0-9]*$/) && number.length <= 11) {
      setFields({ ...fields, username: number });
    }
  };

  const handleAreaCode = (code) => {
    // check if it is a valid number
    if (code.match(/^[0-9]*$/) && code.length <= 6) {
      setFields({ ...fields, area_code: code });
    }
  };

  const handleDailyQuota = (quota) => {
    // check if it is a valid number
    if (quota.match(/^[0-9]*$/)) {
      setFields({ ...fields, daily_quota: quota });
    }
  };

  const copyToClipboard = (password) => {
    // copy the password to clipboard
    navigator.clipboard.writeText(password);
    setMessage({ message: "Password copied to clipboard", type: "success" });
    setShowToast(true);
  };

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-md-12">
          <p className="h1 title text-center">ADD USER</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div col="12" className="col-md-5">
          <div className="card text-dark my-2 mx-auto">
            <div className="card-body">
              {/* AddUser logo */}
              <div className="row justify-content-center">
                <FontAwesomeIcon
                  icon={faUser}
                  className="mr-2"
                  style={{ fontSize: 50, color: "#f26520" }}
                />
              </div>
              <div className="col-12 mt-2">
                <select
                  className="form-select mb-3 w-100"
                  style={{ height: 60 }}
                  value={fields.role}
                  onChange={(e) =>
                    setFields({ ...fields, role: e.target.value })
                  }
                >
                  <option value="">Select Role</option>
                  <option value="Admin">Admin</option>
                  <option value="Staff">Staff</option>
                </select>
              </div>

              <div className="col-12 mt-2">
                <select
                  className="form-select mb-3 w-100"
                  style={{ height: 60 }}
                  value={fields.type}
                  onChange={(e) =>
                    setFields({ ...fields, role: e.target.value })
                  }
                >
                  <option value="">Select Type</option>
                  <option value="patient">Patient</option>
                  <option value="consumer">Consumer</option>
                </select>
              </div>

              <div className="col-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    id="floatingInput"
                    className="form-control mb-3 w-100 text-dark"
                    placeholder="First Name"
                    style={styles.input}
                    value={fields.first_name}
                    onChange={(e) =>
                      setFields({ ...fields, first_name: e.target.value })
                    }
                  />
                  <label htmlFor="floatingInput">First Name</label>
                </div>
              </div>

              <div className="col-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    id="floatingInput"
                    className="form-control mb-3 w-100 text-dark"
                    placeholder="Last Name"
                    style={styles.input}
                    value={fields.last_name}
                    onChange={(e) =>
                      setFields({ ...fields, last_name: e.target.value })
                    }
                  />
                  <label htmlFor="floatingInput">Last Name</label>
                </div>
              </div>

              <div className="col-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    id="floatingInput"
                    className="form-control mb-3 w-100 text-dark"
                    placeholder="Mobile Number"
                    style={styles.input}
                    value={fields.username}
                    onChange={(e) => handleMobileNumber(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Mobile Number</label>
                </div>
              </div>
              {/* 
              <div className='col-12'>
                <div className='form-floating mb-3'>
                  <input
                    type='text'
                    id='floatingInput'
                    className='form-control mb-3 w-100 text-dark'
                    placeholder='Area Code'
                    style={styles.input}
                    value={fields.area_code}
                    onChange={(e) => handleAreaCode(e.target.value)}
                  />
                  <label htmlFor='floatingInput'>Area Code</label>
                </div>
              </div> */}

              <div className="col-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    id="floatingInput"
                    className="form-control mb-3 w-100 text-dark"
                    placeholder="Daily Quota"
                    style={styles.input}
                    value={fields.daily_quota}
                    onChange={(e) => handleDailyQuota(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Daily Quota</label>
                </div>
              </div>

              {password !== "" && (
                <div className="col-12 mb-3 text-center d-flex justify-content-center">
                  <text className="text-success input-group-text">
                    <strong>PASSWORD:</strong>
                    <span className="text-dark">{password}</span>
                  </text>
                  {/* Copy to clipboard */}
                  <button
                    className="btn text-white"
                    onClick={() => copyToClipboard(password)}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </div>
              )}
              <button
                className="btn btn-lg btn-block"
                style={styles.button}
                type="submit"
                onClick={handleFields}
                disabled={disabled}
              >
                {disabled ? "Please wait..." : "Create User"}
              </button>
              <button
                className="btn btn-lg btn-block text-dark"
                style={styles.button}
                type="submit"
                onClick={() => (window.location.href = "/user")}
                ref={(el) =>
                  el &&
                  el.style.setProperty(
                    "background-color",
                    "#d5d5d5",
                    "important"
                  )
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomToast
        type={details.type}
        message={details.message}
        title="Add User"
        show={showToast}
        setShow={setShowToast}
      />
    </div>
  );
}

export default AddUser;
