const styles = {
  table: {
    marginTop: "2%",
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
    height: 200,
    zIndex: 0,
  },

  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
  },
};

export default styles;
