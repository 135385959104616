import React, { useState, useEffect } from "react";
import CustomToast from "../../components/CustomToast.js";
import CustomTable from "../../components/CustomTable/CustomTable.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { exportForm, searchForms, searchStores } from "../../utils/api/store";
import moment from "moment";
import CustomMap from "../../components/CustomMap/CustomMap";
import { shortenUrl } from "../../utils/api/urlShortener";
import { Col, Row } from "react-bootstrap";
import { getTrackerReport } from "../../utils/api/reportsAPI.js";

function Login({ setInactive }) {
  const [details, setMessage] = useState({ message: "", type: "" });
  const [showToast, setShowToast] = useState(false);
  const [data, setData] = useState([]);
  const [mapUrls, setMapUrls] = useState([]);
  const [searchData, setSearchData] = useState({
    encodedBy: "",
    dateTo: new Date(),
    dateFrom: new Date(),
  });
  const [show, setShow] = useState(false);
  const [exportable, setExportable] = useState({ items: [], headers: [] });
  const [backdrop, setBackdrop] = useState(true);
  const [mapData, setMapData] = useState({
    lat: 0,
    lng: 0,
    address: "",
    store_name: "",
    photo: "",
  });
  const [signatureURL, setSignatureURL] = useState("");
  const [showSignature, setShowSignature] = useState(false);
  const [page, setPage] = useState("1");
  const [size, setSize] = useState("");

  const headers = [
    "Date",
    "Encoded By",
    "Encoded On",
    "Classification Type",
    "Activation Type",
    "ANC Name",
    "Clinic/Hospital",
    "Name of Doctor/HCP",
    "Address 1",
    "Address 2",
    "Long/Lat 1",
    "Long/Lat 2",
    "Photo",
    "Customer Name",
    "Customer Phone Number",
    "Customer Email/FB Account",
    "DIABETASOL SWEET 50x1g",

    "DIABETASOL-VAN 180g",
    "DIABETASOL-VAN 360g",
    "DIABETASOL-VAN 600g",
    "DIABETASOL-VAN 800g",
    "DIABETASOL-VAN 1kg",

    "DIABETASOL-CHO 180g",
    "DIABETASOL-CHO 360g",
    "DIABETASOL-CHO 600g",

    "DIABETASOL-CAP 180g",
    "DIABETASOL-CAP 600g",

    "ENTRASOL-VAN 200g",
    "ENTRASOL-VAN 600g",

    "ENTRASOL-CHO 200g",
    "ENTRASOL-CHO 600g",
    "Promo Packs",
    "Sample Given",
    "OR Number",
    "Diabetasol Diagnosis",
    "Entrasol Diagnosis",
    "Diabetasol Sweetener Diagnosis",
    "Best Time to Call",
    "Patient Type",
    "LC/NP/PTS",
    "EU/CG",
  ];

  const handleShow = (long, lat, address, store_name, photo) => {
    setMapData({
      lat: lat,
      lng: long,
      address: address,
      store_name: store_name,
      photo: photo,
    });
    setInactive(false);
    setShow(true);
  };

  const handleShowSignature = (url) => {
    setSignatureURL(url);
    setShowSignature(true);
  };

  const handleSearch = async () => {
    setMapUrls([]);
    const response = await searchForms(
      searchData.dateFrom,
      searchData.dateTo,
      searchData.encodedBy,
      "patient",
      page
    );

    if (response?.data?.data) {
      setSize(response.data.size);
      let data = response.data.data;
      let dataToDisplay = [];
      let exportableData = [];
      // Loop through the data and push it to the dataToDisplay array
      let extractedMapUrls = [];
      data.forEach((item) => {
        // item.customers.map((value) => {
        // get the data in each object
        let data = [];
        let exportableTemp = [];

        let storedate = item.date ? item.date.toString() : "";
        // convert to proper date
        let store_date = moment(storedate).format("MMMM Do YYYY");
        data.push(store_date);
        exportableTemp.push(store_date);

        let encoded_by = item.encoded_by ? item.encoded_by.toString() : "";
        data.push(encoded_by);
        exportableTemp.push(encoded_by);

        let addedon = item.encoded_on ? item.encoded_on.toString() : "";
        // convert to proper date
        let added_on = moment(addedon).format("MMMM Do YYYY, h:mm a");
        data.push(added_on);
        exportableTemp.push(added_on);

        let classification_type = item.classification_type
          ? item.classification_type.toString()
          : "";
        data.push(classification_type);
        exportableTemp.push(classification_type);

        let activation_type = item.activation_type
          ? item.activation_type.toString()
          : "";
        data.push(activation_type);
        exportableTemp.push(activation_type);

        let anc_ans_aps = item.anc_ans_aps ? item.anc_ans_aps.toString() : "";
        data.push(anc_ans_aps);
        exportableTemp.push(anc_ans_aps);

        let location_name = item.store_venue ? item.store_venue.toString() : "";
        data.push(location_name);
        exportableTemp.push(location_name);

        let remarks = item.competitors_activity
          ? item.competitors_activity.toString()
          : "";
        data.push(remarks);
        exportableTemp.push(remarks);

        let address1 = item.address1 ? item.address1.toString() : "";
        data.push(address1);
        exportableTemp.push(item.address1 ? item.address1.toString() : "");

        let address2 = item.address2 ? item.address2.toString() : "";
        data.push(address2);
        exportableTemp.push(address2);

        let long1 = item.long_lat_1
          ? item.long_lat_1.split("/")[0].toString()
          : "";
        let lat1 = item.long_lat_1
          ? item.long_lat_1.split("/")[1].toString()
          : "";
        let long1lat1 = item.long_lat_1 ? item.long_lat_1.toString() : "";
        data.push(long1lat1);
        exportableTemp.push(long1lat1);
        let long2lat2 = item.long_lat_2 ? item.long_lat_2.toString() : "";
        data.push(long2lat2);
        exportableTemp.push(long2lat2);

        let photo = item.photo ? item.photo.toString() : null;
        // current domain plus the path to the image
        let mapUrl =
          window.location.origin +
          "/public_map?lat=" +
          lat1 +
          "&lng=" +
          long1 +
          "&address=" +
          address1 +
          "&store_name=" +
          location_name +
          "&photo=" +
          photo;

        extractedMapUrls.push(mapUrl);
        if (photo) {
          data.push(
            <button
              onClick={() =>
                handleShow(long1, lat1, address1, location_name, photo)
              }
            >
              View
            </button>
          );
          exportableTemp.push(mapUrl);
        } else {
          let reason_image = item.reason_image
            ? item.reason_image.toString()
            : "";
          data.push(reason_image);
          exportableTemp.push(reason_image);
        }

        let custname = item.customer_name ? item.customer_name.toString() : "";
        data.push(custname);
        exportableTemp.push(custname);

        let contact_no = item.contact_no ? item.contact_no.toString() : "";
        data.push(contact_no);
        exportableTemp.push(contact_no);
        let emailfb = item.email ? item.email.toString() : "";
        data.push(emailfb);
        exportableTemp.push(emailfb);

        //productcodes
        let DIABETASOL_SWEET_50x1g = item.DIABETASOL_SWEET_50x1g
          ? item.DIABETASOL_SWEET_50x1g.toString()
          : "";
        data.push(DIABETASOL_SWEET_50x1g);
        exportableTemp.push(DIABETASOL_SWEET_50x1g);
        let DIABETASOL_VAN_180g = item.DIABETASOL_VAN_180g
          ? item.DIABETASOL_VAN_180g.toString()
          : "";
        data.push(DIABETASOL_VAN_180g);
        exportableTemp.push(DIABETASOL_VAN_180g);
        let DIABETASOL_VAN_360g = item.DIABETASOL_VAN_360g
          ? item.DIABETASOL_VAN_360g.toString()
          : "";
        data.push(DIABETASOL_VAN_360g);
        exportableTemp.push(DIABETASOL_VAN_360g);
        let DIABETASOL_VAN_600g = item.DIABETASOL_VAN_600g
          ? item.DIABETASOL_VAN_600g.toString()
          : "";
        data.push(DIABETASOL_VAN_600g);
        exportableTemp.push(DIABETASOL_VAN_600g);
        let DIABETASOL_VAN_800g = item.DIABETASOL_VAN_800g
          ? item.DIABETASOL_VAN_800g.toString()
          : "";
        data.push(DIABETASOL_VAN_800g);
        exportableTemp.push(DIABETASOL_VAN_800g);
        let DIABETASOL_VAN_1kg = item.DIABETASOL_VAN_1kg
          ? item.DIABETASOL_VAN_1kg.toString()
          : "";
        data.push(DIABETASOL_VAN_1kg);
        exportableTemp.push(DIABETASOL_VAN_1kg);
        let DIABETASOL_CHO_180g = item.DIABETASOL_CHO_180g
          ? item.DIABETASOL_CHO_180g.toString()
          : "";
        data.push(DIABETASOL_CHO_180g);
        exportableTemp.push(DIABETASOL_CHO_180g);
        let DIABETASOL_CHO_360g = item.DIABETASOL_CHO_360g
          ? item.DIABETASOL_CHO_360g.toString()
          : "";
        data.push(DIABETASOL_CHO_360g);
        exportableTemp.push(DIABETASOL_CHO_360g);
        let DIABETASOL_CHO_600g = item.DIABETASOL_CHO_600g
          ? item.DIABETASOL_CHO_600g.toString()
          : "";
        data.push(DIABETASOL_CHO_600g);
        exportableTemp.push(DIABETASOL_CHO_600g);
        let DIABETASOL_CAP_180g = item.DIABETASOL_CAP_180g
          ? item.DIABETASOL_CAP_180g.toString()
          : "";
        data.push(DIABETASOL_CAP_180g);
        exportableTemp.push(DIABETASOL_CAP_180g);
        let DIABETASOL_CAP_600g = item.DIABETASOL_CAP_600g
          ? item.DIABETASOL_CAP_600g.toString()
          : "";
        data.push(DIABETASOL_CAP_600g);
        exportableTemp.push(DIABETASOL_CAP_600g);
        let ENTRASOL_VAN_200g = item.ENTRASOL_VAN_200g
          ? item.ENTRASOL_VAN_200g.toString()
          : "";
        data.push(ENTRASOL_VAN_200g);
        exportableTemp.push(ENTRASOL_VAN_200g);
        let ENTRASOL_VAN_600g = item.ENTRASOL_VAN_600g
          ? item.ENTRASOL_VAN_600g.toString()
          : "";
        data.push(ENTRASOL_VAN_600g);
        exportableTemp.push(ENTRASOL_VAN_600g);
        let ENTRASOL_CHO_200g = item.ENTRASOL_CHO_200g
          ? item.ENTRASOL_CHO_200g.toString()
          : "";
        data.push(ENTRASOL_CHO_200g);
        exportableTemp.push(ENTRASOL_CHO_200g);
        let ENTRASOL_CHO_600g = item.ENTRASOL_CHO_600g
          ? item.ENTRASOL_CHO_600g.toString()
          : "";
        data.push(ENTRASOL_CHO_600g);
        exportableTemp.push(ENTRASOL_CHO_600g);
        // var headerInd = 16;
        // var productArr = item.purchased_products.split(",");
        // productArr.map((prod) => {
        //   if (prod.split("~")[0] === headers[headerInd] && headerInd < 31) {
        //     data.push(prod.split("~")[1]);
        //     exportableTemp.push(prod.split("~")[1]);
        //   }
        //   headerInd = headerInd + 1;
        // });

        let promo_packs = item.promo_packs ? item.promo_packs.toString() : "";
        data.push(promo_packs);
        exportableTemp.push(promo_packs);
        let sample_given = item.sample_given
          ? item.sample_given.toString()
          : "";
        data.push(sample_given);
        exportableTemp.push(sample_given);
        let or_no = item.or_no ? item.or_no.toString() : "";
        data.push(or_no);
        exportableTemp.push(or_no);
        let diabetasol_diagnosis = item.diabetasol_diagnosis
          ? item.diabetasol_diagnosis.toString()
          : "";
        data.push(diabetasol_diagnosis);
        exportableTemp.push(diabetasol_diagnosis);
        let entrasol_diagnosis = item.entrasol_diagnosis
          ? item.entrasol_diagnosis.toString()
          : "";
        data.push(entrasol_diagnosis);
        exportableTemp.push(entrasol_diagnosis);
        let diabetasol_sweetener_diagnosis = item.diabetasol_sweetener_diagnosis
          ? item.diabetasol_sweetener_diagnosis.toString()
          : "";
        data.push(diabetasol_sweetener_diagnosis);
        exportableTemp.push(diabetasol_sweetener_diagnosis);
        let call_time = item.call_time ? item.call_time.toString() : "";
        data.push(call_time);
        exportableTemp.push(call_time);
        let bumo = item.bumo ? item.bumo.toString() : "";
        data.push(bumo);
        exportableTemp.push(bumo);
        let lc_nc_p = item.lc_nc_p ? item.lc_nc_p.toString() : "";
        data.push(lc_nc_p);
        exportableTemp.push(lc_nc_p);
        let eu_cg = item.eu_cg ? item.eu_cg.toString() : "";
        data.push(eu_cg);
        exportableTemp.push(eu_cg);

        dataToDisplay.push(data);
        exportableData.push(exportableTemp);
        // });
      });

      //transform map urls to short urls
      extractedMapUrls.forEach(async (url, index) => {
        const shortenedUrl = await shortenUrl(url);
        setMapUrls((mapUrls) => [...mapUrls, shortenedUrl + " " + index]);
      });

      setExportable({ items: exportableData, headers: headers });
      setData(dataToDisplay);
    } else {
      let responseMessage = response?.data?.messages?.error;
      setMessage({
        message: responseMessage ?? "Something went wrong.",
        type: "danger",
      });
      setShowToast(true);
    }
  };

  const handlePaginateSearch = async (pagecount) => {
    setData([]);
    const response = await searchForms(
      searchData.dateFrom,
      searchData.dateTo,
      searchData.encodedBy,
      "patient",
      pagecount
    );

    if (response?.data?.data) {
      setSize(response.data.size);
      let data = response.data.data;
      let dataToDisplay = [];
      let exportableData = [];
      // Loop through the data and push it to the dataToDisplay array
      let extractedMapUrls = [];

      data.forEach((item) => {
        // item.customers.map((value) => {
        // get the data in each object
        let data = [];
        let exportableTemp = [];

        let storedate = item.date ? item.date.toString() : "";
        // convert to proper date
        let store_date = moment(storedate).format("MMMM Do YYYY");
        data.push(store_date);
        exportableTemp.push(store_date);

        let encoded_by = item.encoded_by ? item.encoded_by.toString() : "";
        data.push(encoded_by);
        exportableTemp.push(encoded_by);

        let addedon = item.encoded_on ? item.encoded_on.toString() : "";
        // convert to proper date
        let added_on = moment(addedon).format("MMMM Do YYYY, h:mm a");
        data.push(added_on);
        exportableTemp.push(added_on);

        let classification_type = item.classification_type
          ? item.classification_type.toString()
          : "";
        data.push(classification_type);
        exportableTemp.push(classification_type);

        let activation_type = item.activation_type
          ? item.activation_type.toString()
          : "";
        data.push(activation_type);
        exportableTemp.push(activation_type);

        let anc_ans_aps = item.anc_ans_aps ? item.anc_ans_aps.toString() : "";
        data.push(anc_ans_aps);
        exportableTemp.push(anc_ans_aps);

        let location_name = item.store_venue ? item.store_venue.toString() : "";
        data.push(location_name);
        exportableTemp.push(location_name);

        let remarks = item.competitors_activity
          ? item.competitors_activity.toString()
          : "";
        data.push(remarks);
        exportableTemp.push(remarks);

        let address1 = item.address1 ? item.address1.toString() : "";
        data.push(address1);
        exportableTemp.push(item.address1 ? item.address1.toString() : "");

        let address2 = item.address2 ? item.address2.toString() : "";
        data.push(address2);
        exportableTemp.push(address2);

        let long1 = item.long_lat_1
          ? item.long_lat_1.split("/")[0].toString()
          : "";
        let lat1 = item.long_lat_1
          ? item.long_lat_1.split("/")[1].toString()
          : "";
        let long1lat1 = item.long_lat_1 ? item.long_lat_1.toString() : "";
        data.push(long1lat1);
        exportableTemp.push(long1lat1);
        let long2lat2 = item.long_lat_2 ? item.long_lat_2.toString() : "";
        data.push(long2lat2);
        exportableTemp.push(long2lat2);

        let photo = item.photo ? item.photo.toString() : null;
        // current domain plus the path to the image
        let mapUrl =
          window.location.origin +
          "/public_map?lat=" +
          lat1 +
          "&lng=" +
          long1 +
          "&address=" +
          address1 +
          "&store_name=" +
          location_name +
          "&photo=" +
          photo;

        extractedMapUrls.push(mapUrl);
        if (photo) {
          data.push(
            <button
              onClick={() =>
                handleShow(long1, lat1, address1, location_name, photo)
              }
            >
              View
            </button>
          );
          exportableTemp.push(mapUrl);
        } else {
          let reason_image = item.reason_image
            ? item.reason_image.toString()
            : "";
          data.push(reason_image);
          exportableTemp.push(reason_image);
        }

        let custname = item.customer_name ? item.customer_name.toString() : "";
        data.push(custname);
        exportableTemp.push(custname);

        let contact_no = item.contact_no ? item.contact_no.toString() : "";
        data.push(contact_no);
        exportableTemp.push(contact_no);
        let emailfb = item.email ? item.email.toString() : "";
        data.push(emailfb);
        exportableTemp.push(emailfb);

        //productcodes
        let DIABETASOL_SWEET_50x1g = item.DIABETASOL_SWEET_50x1g
          ? item.DIABETASOL_SWEET_50x1g.toString()
          : "";
        data.push(DIABETASOL_SWEET_50x1g);
        exportableTemp.push(DIABETASOL_SWEET_50x1g);
        let DIABETASOL_VAN_180g = item.DIABETASOL_VAN_180g
          ? item.DIABETASOL_VAN_180g.toString()
          : "";
        data.push(DIABETASOL_VAN_180g);
        exportableTemp.push(DIABETASOL_VAN_180g);
        let DIABETASOL_VAN_360g = item.DIABETASOL_VAN_360g
          ? item.DIABETASOL_VAN_360g.toString()
          : "";
        data.push(DIABETASOL_VAN_360g);
        exportableTemp.push(DIABETASOL_VAN_360g);
        let DIABETASOL_VAN_600g = item.DIABETASOL_VAN_600g
          ? item.DIABETASOL_VAN_600g.toString()
          : "";
        data.push(DIABETASOL_VAN_600g);
        exportableTemp.push(DIABETASOL_VAN_600g);
        let DIABETASOL_VAN_800g = item.DIABETASOL_VAN_800g
          ? item.DIABETASOL_VAN_800g.toString()
          : "";
        data.push(DIABETASOL_VAN_800g);
        exportableTemp.push(DIABETASOL_VAN_800g);
        let DIABETASOL_VAN_1kg = item.DIABETASOL_VAN_1kg
          ? item.DIABETASOL_VAN_1kg.toString()
          : "";
        data.push(DIABETASOL_VAN_1kg);
        exportableTemp.push(DIABETASOL_VAN_1kg);
        let DIABETASOL_CHO_180g = item.DIABETASOL_CHO_180g
          ? item.DIABETASOL_CHO_180g.toString()
          : "";
        data.push(DIABETASOL_CHO_180g);
        exportableTemp.push(DIABETASOL_CHO_180g);
        let DIABETASOL_CHO_360g = item.DIABETASOL_CHO_360g
          ? item.DIABETASOL_CHO_360g.toString()
          : "";
        data.push(DIABETASOL_CHO_360g);
        exportableTemp.push(DIABETASOL_CHO_360g);
        let DIABETASOL_CHO_600g = item.DIABETASOL_CHO_600g
          ? item.DIABETASOL_CHO_600g.toString()
          : "";
        data.push(DIABETASOL_CHO_600g);
        exportableTemp.push(DIABETASOL_CHO_600g);
        let DIABETASOL_CAP_180g = item.DIABETASOL_CAP_180g
          ? item.DIABETASOL_CAP_180g.toString()
          : "";
        data.push(DIABETASOL_CAP_180g);
        exportableTemp.push(DIABETASOL_CAP_180g);
        let DIABETASOL_CAP_600g = item.DIABETASOL_CAP_600g
          ? item.DIABETASOL_CAP_600g.toString()
          : "";
        data.push(DIABETASOL_CAP_600g);
        exportableTemp.push(DIABETASOL_CAP_600g);
        let ENTRASOL_VAN_200g = item.ENTRASOL_VAN_200g
          ? item.ENTRASOL_VAN_200g.toString()
          : "";
        data.push(ENTRASOL_VAN_200g);
        exportableTemp.push(ENTRASOL_VAN_200g);
        let ENTRASOL_VAN_600g = item.ENTRASOL_VAN_600g
          ? item.ENTRASOL_VAN_600g.toString()
          : "";
        data.push(ENTRASOL_VAN_600g);
        exportableTemp.push(ENTRASOL_VAN_600g);
        let ENTRASOL_CHO_200g = item.ENTRASOL_CHO_200g
          ? item.ENTRASOL_CHO_200g.toString()
          : "";
        data.push(ENTRASOL_CHO_200g);
        exportableTemp.push(ENTRASOL_CHO_200g);
        let ENTRASOL_CHO_600g = item.ENTRASOL_CHO_600g
          ? item.ENTRASOL_CHO_600g.toString()
          : "";
        data.push(ENTRASOL_CHO_600g);
        exportableTemp.push(ENTRASOL_CHO_600g);
        // var headerInd = 16;
        // var productArr = item.purchased_products.split(",");
        // productArr.map((prod) => {
        //   if (prod.split("~")[0] === headers[headerInd] && headerInd < 31) {
        //     data.push(prod.split("~")[1]);
        //     exportableTemp.push(prod.split("~")[1]);
        //   }
        //   headerInd = headerInd + 1;
        // });

        let promo_packs = item.promo_packs ? item.promo_packs.toString() : "";
        data.push(promo_packs);
        exportableTemp.push(promo_packs);
        let sample_given = item.sample_given
          ? item.sample_given.toString()
          : "";
        data.push(sample_given);
        exportableTemp.push(sample_given);
        let or_no = item.or_no ? item.or_no.toString() : "";
        data.push(or_no);
        exportableTemp.push(or_no);
        let diabetasol_diagnosis = item.diabetasol_diagnosis
          ? item.diabetasol_diagnosis.toString()
          : "";
        data.push(diabetasol_diagnosis);
        exportableTemp.push(diabetasol_diagnosis);
        let entrasol_diagnosis = item.entrasol_diagnosis
          ? item.entrasol_diagnosis.toString()
          : "";
        data.push(entrasol_diagnosis);
        exportableTemp.push(entrasol_diagnosis);
        let diabetasol_sweetener_diagnosis = item.diabetasol_sweetener_diagnosis
          ? item.diabetasol_sweetener_diagnosis.toString()
          : "";
        data.push(diabetasol_sweetener_diagnosis);
        exportableTemp.push(diabetasol_sweetener_diagnosis);
        let call_time = item.call_time ? item.call_time.toString() : "";
        data.push(call_time);
        exportableTemp.push(call_time);
        let bumo = item.bumo ? item.bumo.toString() : "";
        data.push(bumo);
        exportableTemp.push(bumo);
        let lc_nc_p = item.lc_nc_p ? item.lc_nc_p.toString() : "";
        data.push(lc_nc_p);
        exportableTemp.push(lc_nc_p);
        let eu_cg = item.eu_cg ? item.eu_cg.toString() : "";
        data.push(eu_cg);
        exportableTemp.push(eu_cg);

        dataToDisplay.push(data);
        exportableData.push(exportableTemp);
        // });
      });

      //transform map urls to short urls
      extractedMapUrls.forEach(async (url, index) => {
        const shortenedUrl = await shortenUrl(url);
        setMapUrls((mapUrls) => [...mapUrls, shortenedUrl + " " + index]);
      });
      setExportable({ items: exportableData, headers: headers });
      setData(dataToDisplay);
    } else {
      let responseMessage = response?.data?.messages?.error;
      setMessage({
        message: responseMessage ?? "Something went wrong.",
        type: "danger",
      });
      setShowToast(true);
    }
  };

  async function handleExport() {
    const response = await exportForm(
      searchData.dateFrom,
      searchData.dateTo,
      searchData.encodedBy,
      "patient"
    );
    if (response.data) {
      window.open(response.data.url, "_blank");
    }
  }

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    if (mapUrls.length === data.length && mapUrls[0]?.includes(" ")) {
      // order the map urls by index
      let orderedMapUrls = mapUrls.sort((a, b) => {
        return a.split(" ")[1] - b.split(" ")[1];
      });
      // remove the index from the map urls
      orderedMapUrls = orderedMapUrls.map((url) => url.split(" ")[0]);
      setMapUrls(orderedMapUrls);
    }
  }, [mapUrls, data.length]);

  return (
    <div className="container-fluid">
      <div className="row text-center mt-4">
        <div className="col-md-12">
          <p className="h1 title">PATIENTS</p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="mt-3 col-12 col-sm-5">
          <InputGroup className="mb-sm-3">
            <Form.Control
              placeholder="Search"
              aria-label="Username"
              value={searchData.encodedBy}
              onChange={(e) =>
                setSearchData({ ...searchData, encodedBy: e.target.value })
              }
            />
          </InputGroup>
        </div>
        <div className="mt-3 col-12 col-sm-2">
          <DatePicker
            selected={searchData.dateFrom}
            onChange={(date) =>
              setSearchData({ ...searchData, dateFrom: date })
            }
            className="form-control"
          />
        </div>

        <div className="mt-3 col-12 col-sm-2">
          <DatePicker
            selected={searchData.dateTo}
            onChange={(date) => setSearchData({ ...searchData, dateTo: date })}
            className="form-control"
          />
        </div>

        <div className="mt-3 col-12 col-sm-3">
          <Button
            variant="primary"
            className="w-100 m-0"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </div>
      <div className="row justify-content-center">
        <CustomTable
          size={size}
          items={data}
          mapUrls={mapUrls}
          setItems={setData}
          headers={headers}
          itemsPerPage={10}
          title="Stores"
          exportable={exportable}
          photoIndex={9}
          beginningIndex={0}
          endIndex={10}
          handleExport={handleExport}
          type="form-report"
          setPage={setPage}
          handlePaginate={handlePaginateSearch}
        />
      </div>
      <CustomToast
        type={details.type}
        message={details.message}
        title="Store"
        show={showToast}
        setShow={setShowToast}
      />
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
        className="p-0"
        style={{ zIndex: backdrop ? 99999 : 0 }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Store Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <CustomMap mapData={mapData} setBackdrop={setBackdrop} />
        </Modal.Body>
      </Modal>
      {/* show signature */}
      <Modal
        show={showSignature}
        onHide={() => {
          setShowSignature(false);
        }}
        centered
        className="p-0"
        style={{ zIndex: backdrop ? 99999 : 0 }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Signature Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <img src={signatureURL} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Login;
