const sidebar = {
  transition: "transform .3s ease-out",
  boxShadow: "inset -1px 0 0 rgba(0, 0, 0, .1)",
  transform: "translateX(-100%)",
  display: "none",
  width: 0,
  "&.open": {
    transition: "transform .3s ease-out",
    transform: "translateX(0)",
    width: 250,
    boxShadow: "inset -1px 0 0 rgba(0, 0, 0, .1)",
    height: "100vh",
    backgroundColor: "#fff",
    zIndex: 1000,
  },
};

const navLink = {
  color: "#000",
  fontSize: "1.2rem",
  fontWeight: "bold",
  textDecoration: "none",
  width: "100%",
  display: "flex",
  "&:hover": {
    color: "#f26520",
  },
};

const accordionHeader = {
  backgroundColor: "#fff",
  border: "none",
  color: "#000",
  fontSize: "1.2rem",
  fontWeight: "bold",
  textDecoration: "none",
  "&:hover": {
    color: "#f26520",
    zIndex: 1000,
  },
  zIndex: 1000,
};

const bubbleButton = {
  display: "none",
  "&.open": {
    transform: "translateX(0)",
    transition: "transform .6s ease-out",
    position: "fixed",
    top: 0,
    left: 10,
    width: 50,
    height: 50,
    borderRadius: "50%",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
    backgroundColor: "#f26520",
    color: "#fff",
    zIndex: 1000,
  },
  zIndex: 1000,
};

const active = {
  color: "#000",
  fontSize: "1.2rem",
  fontWeight: "bold",
  textDecoration: "none",
  "&:hover": {
    color: "#f26520",
  },
  backgroundColor: "#f26520",
  color: "#fff",
  zIndex: 1000,
};

const styles = {
  sidebar,
  navLink,
  accordionHeader,
  bubbleButton,
};

export default styles;
