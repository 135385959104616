import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faTimes,
  faBars,
  faCalendar,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";
import SubMenu from "./SubMenu";
import { Nav, Button } from "react-bootstrap";
import styles from "./Sidebar.styles.js";
import { logoutUser } from "../../utils/api/authentication.js";
import { getRole, refreshPage } from "../../utils/commons";
import CustomToast from "../CustomToast";
import { NavLink } from "react-router-dom";

function SideBar({ toggle, isOpen }) {
  const [details, setMessage] = useState({ message: "", type: "" });
  const [showToast, setShowToast] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [navItems, setNavItems] = useState([]);

  // check if there is changes in screen size
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const handleLogout = async () => {
    const response = await logoutUser();

    if (response.status === 200) {
      setMessage({ message: response?.data?.message, type: "success" });
      setShowToast(true);
      refreshPage();
    } else {
      const responseMessage = response?.data?.messages?.error;
      setMessage({ message: responseMessage, type: "danger" });
      setShowToast(true);
      refreshPage();
    }
  };

  //HR role
  const customerLevelItems = [
    {
      icon: faHome,
      name: "Attendance",
      link: "/attendance",
    },
  ];

  //admin NKA, LKA
  const NKALKALevel = [
    {
      icon: faHome,
      name: "Trackers",
      link: "/tracker-reports",
    },
    {
      icon: faHome,
      name: "Consumer",
      link: "/reports/consumers",
    },
    {
      icon: faCalendar,
      name: "Attendance",
      link: "/attendance",
    },
    {
      icon: faUser,
      name: "Users",
      link: "/user",
    },
  ];

  //superadmin
  const superAdminLevel = [
    {
      icon: faHome,
      name: "Trackers",
      link: "/tracker-reports",
    },
    {
      icon: faHome,
      name: "Patients",
      link: "/reports/patients",
    },
    {
      icon: faHome,
      name: "Consumers",
      link: "/reports/consumers",
    },
    {
      icon: faCalendar,
      name: "Attendance",
      link: "/attendance",
    },
    {
      icon: faUser,
      name: "Users",
      link: "/user",
    },
  ];

  useEffect(() => {
    var role = getRole();

    if (role === "hr") {
      setNavItems(customerLevelItems);
    }
    if (role === "lka admin" || role === "nka admin") {
      setNavItems(NKALKALevel);
    }
    if (role === "admin") {
      setNavItems(superAdminLevel);
    }
  }, []);

  return (
    <>
      <Nav
        className="flex-column"
        style={isOpen ? styles.sidebar["&.open"] : styles.sidebar}
      >
        {/* Add Logo */}
        <Nav.Item className="nav-item">
          <Nav.Link href="/" className="nav-link">
            <img
              src="../../logo.png"
              alt="logo"
              className="img-fluid w-50 mx-auto d-block"
            />
          </Nav.Link>
          <Nav.Link onClick={toggle} className="position-fixed top-0 end-0">
            <FontAwesomeIcon
              icon={isOpen ? faTimes : faBars}
              style={width > 720 ? { fontSize: "2rem" } : { fontSize: "1rem" }}
            />
          </Nav.Link>
        </Nav.Item>
        {navItems.map((data) => {
          return (
            <Nav.Item>
              <NavLink
                to={data.link}
                className="nav-link"
                style={styles.navLink}
              >
                <FontAwesomeIcon icon={data.icon} className="me-2" />
                {data.name}
              </NavLink>
            </Nav.Item>
          );
        })}

        {/* 
                <SubMenu
                    title="Pages"
                    icon={faCopy}
                    items={["NavLink", "Link2", "Active"]}
                /> */}

        {/* <Nav.Item>
                    <NavLink to="/" style={styles.navLink}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Users
                    </NavLink>
                </Nav.Item> */}

        <Nav.Item>
          <Nav.Link onClick={handleLogout} style={styles.navLink}>
            <FontAwesomeIcon icon={faDoorOpen} className="me-2" />
            Logout
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Button
        style={isOpen ? styles.bubbleButton : styles.bubbleButton["&.open"]}
        onClick={toggle}
        hidden={window.location.pathname === "/public_map"}
      >
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className="mr-2" />
      </Button>
      <CustomToast
        type={details.type}
        message={details.message}
        title="Logout"
        show={showToast}
        setShow={setShowToast}
      />
    </>
  );
}

export default SideBar;
