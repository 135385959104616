import React, { useState } from "react";
import styles from "./Login.styles.js";
import CustomToast from "../../components/CustomToast.js";

import { loginUser } from "../../utils/api/authentication.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faLock,
  faGlobe,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getRole } from "../../utils/commons.js";

function Login() {
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [details, setMessage] = useState({ message: "", type: "" });
  const [showToast, setShowToast] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleLogin = async () => {
    setDisabled(true);

    // if (areaCode.length !== 6) {
    //   setMessage({ message: "Invalid Area Code", type: "danger" });
    //   setShowToast(true);
    //   setDisabled(false);
    //   return;
    // }

    // if (number.length !== 11) {
    //   setMessage({ message: "Invalid number", type: "danger" });
    //   setShowToast(true);
    //   setDisabled(false);
    //   return;
    // }

    const response = await loginUser(number, password, areaCode);
    if (!response?.data?.data?.messages?.error) {
      setMessage({ message: "Login Successful", type: "success" });
      // Redirect to dashboard after 1 second
      setTimeout(() => {
        var role = getRole();

        if (role === "hr") {
          window.location.href = "/attendance";
        } else {
          window.location.href = "/tracker-reports";
        }
      }, 1000);
    } else {
      setMessage({
        message: response?.data?.data?.messages?.error,
        type: "error",
      });
    }
    setDisabled(false);
    setShowToast(true);
  };

  const handleMobileNumber = (number) => {
    // check if it is a valid number
    setNumber(number);
    // if (number.match(/^[0-9]*$/) && number.length <= 11) {
    //   setNumber(number);
    // } else {
    //   setMessage({ message: "Invalid Mobile Number", type: "error" });
    // }
  };

  const handleAreaCode = (code) => {
    // check if it is a valid number
    if (code.match(/^[0-9]*$/) && code.length <= 6) {
      setAreaCode(code);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div col="12" className="col-md-6 col-lg-4">
          <div className="card text-dark my-5 mx-auto" style={styles.container}>
            <div className="card-body p-5 d-flex flex-column align-items-center mx-auto w-100">
              <img src="./logo.png" alt="" className="img-fluid mb-5 w-25" />
              {/* <div className="input-group mb-3">
                <span
                  className="input-group-text"
                  id="basic-addon1"
                  style={{ width: "40px" }}
                >
                  <FontAwesomeIcon icon={faGlobe} />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Area Code"
                  value={areaCode}
                  onChange={(e) => handleAreaCode(e.target.value)}
                />
              </div> */}
              <div className="input-group mb-3">
                <span
                  className="input-group-text"
                  id="basic-addon1"
                  style={{ width: "40px" }}
                >
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  value={number}
                  onChange={(e) => handleMobileNumber(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <input
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button
                className="btn btn-lg btn-block"
                style={styles.button}
                type="submit"
                onClick={handleLogin}
                disabled={disabled}
              >
                {" "}
                Login{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomToast
        type={details.type}
        message={details.message}
        title="Login"
        show={showToast}
        setShow={setShowToast}
      />
    </div>
  );
}

export default Login;
