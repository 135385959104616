import JsPDF from "jspdf";

function PDFDownload({
  data,
  mapUrls = [],
  fileName,
  photoIndex,
  beginningIndex,
  endIndex,
}) {
  const generatePDF = () => {
    const report = new JsPDF("p", "pt", "letter");
    report.html(document.querySelector("#report")).then(() => {
      report.save(fileName + "-" + new Date().toLocaleDateString() + ".pdf");
    });
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <div
          id="report"
          style={{ fontSize: "10px", width: "612px", height: "792px" }}
          className="text-dark"
        >
          {data.items.map((store, index1) => (
            <div
              key={index1}
              className="col-12"
              style={{
                height: "792px",
                width: "612px",
                pageBreakAfter: "always",
                padding: "70px",
              }}
            >
              <div className="text-center">
                {store[10].split("&photo=").length > 1 && (
                  <img
                    src={store[10].split("&photo=")[1]}
                    alt="logo"
                    height={200}
                    width={250}
                    style={{ height: "200px", marginBottom: "50px" }}
                  />
                )}
                {store[10].split("&photo=").length === 1 && (
                  <img
                    src={store[10]}
                    alt="logo"
                    height={200}
                    width={250}
                    style={{ marginBottom: "50px" }}
                  />
                )}
              </div>
              <div>
                <div className="col-12">
                  {mapUrls.length > 0 && (
                    <span className="row" key={-1}>
                      <div>
                        Link to Map:{" "}
                        <a href={mapUrls[index1]}>{mapUrls[index1]}</a>
                      </div>
                    </span>
                  )}
                  {data.headers.map((header, index2) => {
                    if (
                      index2 >= beginningIndex &&
                      index2 <= endIndex &&
                      header !== "Photo"
                    ) {
                      return (
                        <span key={index2} className="row">
                          <div>
                            {header} {": "} {store[index2]}
                          </div>
                        </span>
                      );
                    }
                    return null;
                  })}
                </div>
                {"\n\n\n"}
              </div>
            </div>
          ))}
        </div>
      </div>
      <button onClick={generatePDF} type="button">
        Export PDF
      </button>
    </>
  );
}

export default PDFDownload;
