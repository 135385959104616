import React, { useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const CustomToast = ({ type, message, title, show, setShow }) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [show, setShow]);

  return (
    <>
      <ToastContainer className='p-3' position='top-end'>
        <Toast bg={type === "success" ? "success" : "danger"} show={show}>
          <Toast.Header closeButton={false}>
            <strong className='me-auto table-title text-dark'>{title}</strong>
          </Toast.Header>
          <Toast.Body
            className='text-center'
            style={{ fontSize: "1.2rem", fontWeight: "bold", color: "white" }}
          >
            {message}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default CustomToast;
