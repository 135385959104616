import axios from "axios";
import { logoutUser } from "./authentication";
import { refreshPage } from "../commons";

const getAPIKey = () => {
  const userStr = localStorage.getItem("api-key");
  if (userStr) return userStr;
  else return null;
};

var config = {
  "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
  "user-key": getAPIKey(),
  "Content-Type": "application/json",
};

// API Axios Get Call.
export const getAPICall = async (url, data) => {
  let response;
  try {
    response = await axios.get(url, { headers: config, params: data });
  } catch (error) {
    response = error.response;
    if (error.response.data.messages.error === "Invalid token.") {
      logoutUser();
      refreshPage();
    }
  }
  return response;
};

// API Axios Post Call.
export const postAPICall = async (url, data) => {
  let response;
  try {
    response = await axios.post(url, data, { headers: config });
  } catch (error) {
    response = error.response;
    if (error.response.data.messages.error === "Invalid token.") {
      logoutUser();
      refreshPage();
    }
  }
  return response;
};

// API Axios Put Call.
export const putAPICall = async (url, data) => {
  let response;
  try {
    response = await axios.put(url, { headers: config, params: data });
  } catch (error) {
    response = error.response;
    if (error.response.data.messages.error === "Invalid token.") {
      logoutUser();
      refreshPage();
    }
  }
  return response;
};

// API Axios Delete Call.
export const deleteAPICall = async (url, data) => {
  let response;
  try {
    response = await axios.delete(url, { headers: config, params: data });
  } catch (error) {
    response = error.response;
    if (error.response.data.messages.error === "Invalid token.") {
      logoutUser();
      refreshPage();
    }
  }
  return response;
};
