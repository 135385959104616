import React, { useState, useEffect } from "react";
import CustomToast from "../../components/CustomToast.js";
import CustomTable from "../../components/CustomTable/CustomTable.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { searchAttendance } from "../../utils/api/attendance";
import moment from "moment";
import { Image } from "@chakra-ui/image";

function AttendanceManage() {
  const [details, setMessage] = useState({ message: "", type: "" });
  const [showToast, setShowToast] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({
    agent: "",
    dateTo: new Date(),
    dateFrom: new Date(),
  });
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [exportable, setExportable] = useState({ items: [], headers: [] });
  const [width, setWidth] = useState(window.innerWidth);

  const headers = [
    "Name",
    "Username",
    "Type",
    // "Area Code",
    "Time In",
    "Time Out",
    "Address 1",
    "Address 2",
    "Long/Lat",
    "Photo",
    // "Target Quota",
    // "Reached Quota",
  ];

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const handleClose = () => setShow(false);
  const handleShow = (image, file) => {
    setFileName(file);
    setImage(image);
    setShow(true);
  };

  const handleSearch = async () => {
    const response = await searchAttendance(
      searchData.dateFrom,
      searchData.dateTo,
      searchData.agent
    );

    if (response?.data?.data) {
      let data = response.data.data;
      let dataToDisplay = [];
      let exportableData = [];
      // Loop through the data and push it to the dataToDisplay array
      data.forEach((item) => {
        // get the data in each object
        let data = [];
        let exportableTemp = [];

        data.push(item.agent);
        data.push(item.mobile_number);
        data.push(
          item.role.toLowerCase() !== "supervisor"
            ? `Agent-${item.role}`
            : item.role
        );
        // data.push(item.area_code);
        data.push(
          item.time_in
            ? moment(item.time_in).format("MMMM Do YYYY, h:mm:ss a")
            : "N/A"
        );
        data.push(
          item.time_out
            ? moment(item.time_out).format("MMMM Do YYYY, h:mm:ss a")
            : "N/A"
        );
        data.push(item.address);
        data.push(item.address2);
        let longLat = item.long_lat + ", " + item.long_lat;
        data.push(longLat);
        let link = item?.photo ? item.photo : "";
        let file = link.split("/").pop();
        data.push(<button onClick={() => handleShow(link, file)}>View</button>);
        // data.push(item.target_quota);
        // data.push(item.reached_quota);

        exportableTemp.push(item.agent);
        exportableTemp.push(item.mobile_number);
        exportableTemp.push(
          item.role.toLowerCase() !== "supervisor"
            ? `Agent-${item.role}`
            : item.role
        );
        // exportableTemp.push(item.area_code);
        exportableTemp.push(
          item.time_in
            ? moment(item.time_in).format("MMMM Do YYYY, h:mm:ss a")
            : "N/A"
        );
        exportableTemp.push(
          item.time_out
            ? moment(item.time_out).format("MMMM Do YYYY, h:mm:ss a")
            : "N/A"
        );
        exportableTemp.push(item.address);
        exportableTemp.push(item.address2);
        exportableTemp.push(longLat);
        exportableTemp.push(link);
        // exportableTemp.push(item.target_quota);
        // exportableTemp.push(item.reached_quota);

        dataToDisplay.push(data);
        exportableData.push(exportableTemp);
      });
      setExportable({ items: exportableData, headers: headers });
      setData(dataToDisplay);
    } else {
      let responseMessage = response?.data?.messages?.error;
      setMessage({
        message: responseMessage ?? "Something went wrong.",
        type: "danger",
      });
      setShowToast(true);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row text-center mt-4">
        <div className="col-md-12">
          <p className="h1 title">ATTENDANCES</p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="mt-3 col-12 col-sm-5">
          <InputGroup className="mb-sm-3">
            <Form.Control
              placeholder="Search"
              aria-label="Username"
              value={searchData.agent}
              onChange={(e) =>
                setSearchData({ ...searchData, agent: e.target.value })
              }
            />
          </InputGroup>
        </div>
        <div className="mt-3 col-12 col-sm-2">
          <DatePicker
            selected={searchData.dateFrom}
            onChange={(date) =>
              setSearchData({ ...searchData, dateFrom: date })
            }
            className="form-control"
          />
        </div>

        <div className="mt-3 col-12 col-sm-2">
          <DatePicker
            selected={searchData.dateTo}
            onChange={(date) => setSearchData({ ...searchData, dateTo: date })}
            className="form-control"
          />
        </div>

        <div className="mt-3 col-12 col-sm-3">
          <Button
            variant="primary"
            className="w-100 m-0"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </div>
      <div className="row justify-content-center">
        <CustomTable
          items={data}
          setItems={setData}
          headers={headers}
          itemsPerPage={10}
          title="Attendances"
          exportable={exportable}
          photoIndex={8}
          type="regular"
        />
      </div>
      <CustomToast
        type={details.type}
        message={details.message}
        title="Store"
        show={showToast}
        setShow={setShowToast}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Viewing -{" "}
            {fileName.length > 15
              ? fileName.substring(0, 15) + "..."
              : fileName}
          </Modal.Title>
        </Modal.Header>
        <Image
          src={image}
          fallbackSrc="../../logo.png"
          alt="A Placeholder Image"
          style={{ maxHeight: width > 768 ? "500px" : "150px" }}
          className="img-fluid mx-auto"
        />
      </Modal>
    </div>
  );
}

export default AttendanceManage;
