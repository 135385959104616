import React from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";
import LocationPin from "./LocationPin";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  InfoWindow,
} from "react-google-maps";

function CustomMap({ mapData, setBackdrop = () => {}, fullScreen = false }) {
  const defaultProps = {
    center: {
      // convert to number
      lat: Number(mapData.lat),
      lng: Number(mapData.lng),
    },
    zoom: 18,
    address: mapData.address,
  };

  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => {
      return (
        <GoogleMap
          defaultZoom={defaultProps.zoom}
          defaultCenter={defaultProps.center}
          mapTypeId={"satellite"}

          // onClick={props.onDragEnd}
        >
          <LocationPin
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
            mapData={mapData}
            setBackdrop={setBackdrop}
            defaultProps={defaultProps}
          />
        </GoogleMap>
      );
    })
  );

  return (
    <div
      style={
        fullScreen
          ? { height: "100vh", width: "100%" }
          : { height: "70vh", width: "100%" }
      }
      className="customized-map"
    >
      <MyMapComponent
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyABgGCQG0a7LPPojOK7XLUP_65GavyZNKU&v=3.exp&libraries=places"
        loadingElement={<div style={{ height: `80%` }} />}
        containerElement={<div style={{ height: `80vh` }} />}
        mapElement={<div style={{ height: `80%` }} />}
      />
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAHeix3Mf1YRppXp1hxArkPwkevOZFc1Ew", 
        language: 'ph',
        region: 'ph',
        libraries:['places', 'geometry', 'drawing', 'visualization'],
        // language:"ph",
        // libraries:['visualization']
        }}
        
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        
        //button for change map type
        options={{
          mapTypeControl: true,
          streetViewControl: true,
          fullscreenControl: true,
          mapTypeId: "satellite",
          
        }}
      >
        <LocationPin
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          mapData={mapData}
          setBackdrop={setBackdrop}
        />
      </GoogleMapReact> */}
    </div>
  );
}

export default CustomMap;
