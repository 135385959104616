import Card from "react-bootstrap/Card";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Image } from "@chakra-ui/image";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  InfoWindow
} from "react-google-maps";

function LocationPin({ mapData, setBackdrop, defaultProps }) {
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);

  });

  const closeModal = () => {
    setShowModal(false);
    setBackdrop(true);
  };
  const openModal = () => {
    setBackdrop(false);
    setShowModal(true);
  };

  return (
    <>
      {/* <Card
        style={{
          width: "5rem",
          
          top: "30%",
          left: "50%",
          // top: "50%",
          // left: "50%",
          // transform: `translate(-50%, ${width < 768 ? "-450%":"-310%"})`,
          transform: `translate(-50%, -100%)`,
          zIndex:"2"
          // transform: "translateX(50%)",
        }}
        onClick={() => {
          openModal();
        }}
      >
        <Card.Body className='p-1'>
          <Card.Img variant='top' src={mapData.photo} />
        </Card.Body>
      </Card>

      <span className='beacon'>
        <div className='pin'></div>
        <span className='pin-text'>{mapData.store_name}</span>
      // </span> */}

<Marker
          label={{
            text:mapData.store_name,
            color: 'black',
            fontSize: '20px',
            className:"marker-label-pos"
            }}
          //  title={mapData.store_name}
      position={defaultProps.center}
      // onClick={props.onToggleOpen}
    >
      <InfoWindow onClick={openModal} className="beacon">
        
      	<img src={mapData.photo} width={80} height={80}/>
      </InfoWindow>
      </Marker>

      <Modal show={showModal} onHide={closeModal} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>{mapData.store_name}</Modal.Title>
        </Modal.Header>
        <Image
          src={mapData.photo}
          fallbackSrc='../../logo.png'
          alt='A Placeholder Image'
          // style={{ maxHeight: "768px"}}
          style={{ maxHeight: width > 768 ? "768px" : width }}
          className='img-fluid mx-auto'
        />
      </Modal>
    </>
  );
}

export default LocationPin;
